import { useMemo, useState, useEffect } from "react";
import {
  Route,
  Routes,
  useParams,
  useNavigate,
  useMatch
} from "react-router-dom";

// Api
import { useMutation, useQueryClient } from "react-query";
import { getAdventure } from "@api";
import { useAuthQuery } from "@hooks";

// UI
import { TopBar, ErrorBoundary, Button } from "@components/common";
import BottomNavigation from "./BottomNavigation/BottomNavigation";

// Components
import Map from "@components/map/Map";
import AdventureSpots from "./AdventureSpots/AdventureSpots";
import AdventureNotifications from "./AdventureNotifications/AdventureNotifications";
import AdventureInfo from "./AdventureInfo/AdventureInfo";
import Spot from "./Spot/Spot";
import NewLevelReward from "./Spot/Reward/NewLevelReward";
import DisplayCurrentLevel from "./Spot/Reward/DisplayCurrentLevel";

// Helpers
import { useMarkerImages } from "./useMarkerImages";

// Styles
import styles from "./Adventure.module.css";
import Modal from "../../components/common/Modal/Modal";

// Assets
import { Settings } from "react-feather";
import { useAuth } from "@/auth.js";

export default function Adventure() {
  const { userRole } = useAuth();

  const { adventureId } = useParams();
  const navigate = useNavigate();
  const { data: userAdventure, isLoading } = useAuthQuery(
    ["adventure", { id: adventureId }],
    getAdventure
  );

  const queryClient = useQueryClient();
  const markerImages = useMarkerImages(); // This hook loads up all images and returns an array for the map layers
  const [openAdventureModal, setOpenAdventureModal] = useState(false);
  const [flyToDestination, setFlyToDestination] = useState();
  const spotMatch = useMatch("adventure/:adventureId/spot/:spotId/*");

  const {
    adventure,
    points,
    currentLevel,
    expiredSoon,
    expired,
    accessEndDate,
    showExtensionModal,
    id
  } = userAdventure || {};

  const {
    levels,
    location: adventureCenterPoint,
    zoomLevel,
    extendDuration,
    extensionProductSite,
    extensionTextEn,
    extensionTextFi,
    extensionTextSv,
    notExtensible
  } = adventure || [];

  const spots = useMemo(
    () =>
      levels?.flatMap((level) => {
        const levelNumber = level.levelNumber;
        return (
          level.spots?.map((spot) => {
            const markerImgSrc = markerImages.find((img) => {
              return img.markerIcon === spot.markerIcon;
            })?.markerIcon;
            return { ...spot, levelNumber, markerImgSrc };
          }) || []
        );
      }) || [],
    [levels, markerImages]
  );

  const visibleSpots = useMemo(
    () => spots?.filter((spot) => spot.unlocked) || [],
    [spots]
  );

  const { mutate: openSpotMutation, isError } = useMutation({
    onSuccess: ({ data: updatedSpot }, variables) => {
      queryClient.setQueryData(
        [
          "spot",
          { adventureId: variables.adventureId, spotId: variables.spotId }
        ],
        updatedSpot
      );
      queryClient.invalidateQueries("adventure", {
        adventureId: variables.adventureId
      });
    }
  });

  const handleSpotOpen = ({ spotId, spotVisited }) => {
    if (!spotVisited) {
      openSpotMutation({ adventureId, spotId: spotId });
    }
    navigate(`spot/${spotId}`);
  };
  const handleSpotClick = (location) => {
    navigate(`adventure/${adventureId}`);
    const LngLat = location
      .split(",")
      .reverse()
      .map((coordinate) => coordinate.trim());
    setFlyToDestination(LngLat);
  };

  useEffect(() => {
    if (showExtensionModal === false || notExtensible === true) {
      setOpenAdventureModal(false);
    } else if (expiredSoon === true || expired === true) {
      setOpenAdventureModal(true);
    } else {
      return null;
    }
  }, [expired, expiredSoon, notExtensible, showExtensionModal]);

  return (
    <>
      <div className={styles.wrapper}>
        {userRole === "visitor" ? (
          <TopBar
            title={""}
            left={<Button to={"/settings"} icon={Settings} white />}
            transparent
            absolute
          />
        ) : (
          <TopBar backTo={"/adventures"} transparent absolute />
        )}
        <div className={styles.inner}>
          <div className={styles.scrollContainer}>
            <Routes>
              <Route
                path="spots"
                element={
                  <AdventureSpots
                    points={points}
                    currentLevel={currentLevel}
                    numberOfSpots={spots?.length}
                    levels={levels}
                    markerImages={markerImages}
                    onSpotClick={handleSpotClick}
                  />
                }
              />
              <Route path="info" element={<AdventureInfo spots={spots} />} />
              <Route
                path="notifications"
                element={
                  <AdventureNotifications spots={spots} levels={levels} />
                }
              />
              <Route
                path="spot/:spotId/*"
                element={<Spot currentLevel={currentLevel} />}
              />
              <Route
                path="spot/:spotId/reward/reward-level"
                element={
                  <NewLevelReward
                    levels={levels}
                    spots={spots}
                    currentLevel={currentLevel}
                  />
                }
              />
              <Route
                path="/level/level-reward"
                element={
                  <DisplayCurrentLevel
                    levels={levels}
                    spots={spots}
                    currentLevel={currentLevel}
                  />
                }
              />
            </Routes>
          </div>
          {!isLoading && (
            <ErrorBoundary>
              <Map
                adventureCenterPoint={adventureCenterPoint}
                adventureZoomLevel={zoomLevel}
                spots={visibleSpots}
                onSpotOpen={handleSpotOpen}
                markerImages={markerImages}
                flyToDestination={flyToDestination}
              />
            </ErrorBoundary>
          )}
        </div>
        <Modal
          setOpenAdventureModal={setOpenAdventureModal}
          openAdventureModal={openAdventureModal}
          accessEndDate={accessEndDate}
          extendDuration={extendDuration}
          extensionProductSite={extensionProductSite}
          extensionTextFi={extensionTextFi}
          extensionTextEn={extensionTextEn}
          extensionTextSv={extensionTextSv}
          expired={expired}
          expiredSoon={expiredSoon}
          id={id}
          showExtensionModal={showExtensionModal}
        />
        {!spotMatch && (
          <div className={styles.bottom}>
            <BottomNavigation />
          </div>
        )}
      </div>
    </>
  );
}
