import React, { useState, useEffect } from "react";
import { Button, Text } from "@components/common";
import { XCircle } from "react-feather";
// import axios from "axios";

// site Config
import { logoSrc, logoAlt } from "@/siteConfig";

// Localization
import { useTranslation } from "react-i18next";

//UI
import { Loader } from "@components/common";

//Api
import { approveExtensionPurchase } from "@api";
import { useMutation } from "@hooks";

import styles from "./Modal.module.css";
import { RichText } from "../RichText/RichText";

const ExpiredModal = ({
  setOpenExtensionModal,
  extensionProductSite,
  extensionText,
  openExtensionModal,
  expiredExtensionData
}) => {
  const [activatedTime, setActivatedTime] = useState(false);
  const [approveExtension, setApproveExtension] = useState(false);
  const [getUserLocale, setUserLocale] = useState("");

  const { mutate: purchaseExpiredExtension, isLoading } = useMutation(
    approveExtensionPurchase,
    {
      onSuccess: () => {
        setApproveExtension(true);
      },
      onError: () => {
        setApproveExtension(false);
      }
    }
  );

  const handleSubmitProposal = () => {
    setActivatedTime(true);
  };

  const handleApproval = () => {
    purchaseExpiredExtension({ adventureId: expiredExtensionData.id });
  };

  const handlePayment = () => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(expiredExtensionData.extensionProductSite);
  };

  useEffect(() => {
    setUserLocale(localStorage.getItem("language") || "fi");
  }, [getUserLocale]);

  if (!openExtensionModal) return null;

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalBox}>
        <div
          onClick={() => setOpenExtensionModal(false)}
          className={styles.closeButton}
        >
          <XCircle />
        </div>
        <img src={logoSrc} alt={logoAlt} className={styles.logo} />

        <div className={styles.modalContent}>
          {approveExtension ? (
            <ThankYouPage handlePayment={handlePayment} />
          ) : activatedTime ? (
            <AnswerRenewal
              expiredExtensionData={expiredExtensionData}
              handleApproval={handleApproval}
              setOpenExtensionModal={setOpenExtensionModal}
              isLoading={isLoading}
            />
          ) : (
            <ActivateAdditionalTime
              getUserLocale={getUserLocale}
              expiredExtensionData={expiredExtensionData}
              handleSubmitProposal={handleSubmitProposal}
              extensionText={extensionText}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ActivateAdditionalTime = ({
  getUserLocale,
  expiredExtensionData,
  handleSubmitProposal
}) => {
  const { t } = useTranslation();

  const getExpirationDate = new Date(expiredExtensionData.accessEndDate);
  const expirationDay = getExpirationDate.getDate();
  const expirationMonth = getExpirationDate.getMonth() + 1;
  const expirationYear = getExpirationDate.getFullYear();

  return (
    <div>
      <div className={styles.modalHeading}>
        <p>{t("adventure_extension.extension_heading_message")}</p>
        <p>
          {t("adventure_extension.extension_subheading_message")}{" "}
          {`${expirationDay}.${expirationMonth}.${expirationYear}`}
        </p>
      </div>
      <div>
        <RichText
          content={
            getUserLocale === "fi"
              ? expiredExtensionData.extensionTextFi
              : getUserLocale === "en"
              ? expiredExtensionData.extensionTextEn
              : getUserLocale === "sv"
              ? expiredExtensionData.extensionTextSv
              : null
          }
        />
      </div>
      <div className={styles.modalButton}>
        <Button onClick={handleSubmitProposal}>
          {t("adventure_extension.activate_additional_time")}
        </Button>
      </div>
    </div>
  );
};
const AnswerRenewal = ({
  expiredExtensionData,
  handleApproval,
  setOpenExtensionModal,
  isLoading
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <p> {t("adventure_extension.approval_message_heading")}</p>
      <p>
        {expiredExtensionData.extendDuration} {t("adventure_extension.months")}
      </p>
      <div className={styles.doubleModalButton}>
        <Button onClick={handleApproval} type="submit" disabled={isLoading}>
          {isLoading && (
            <div className={styles.empty}>
              <div>
                <Loader size="sm" color="gray" />
              </div>
            </div>
          )}
          {t("adventure_extension.extension_approval")}
        </Button>
        <Button
          onClick={() => setOpenExtensionModal(false)}
          type="submit"
          disabled={isLoading}
        >
          {t("adventure_extension.extension_disapproval")}
        </Button>
      </div>
      <Text>{t("adventure_extension.additional_payment_message")}</Text>
    </div>
  );
};
const ThankYouPage = ({ handlePayment }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Text>{t("adventure_extension.thank_you_message")} </Text>
      <Text>{t("adventure_extension.payment_message")} </Text>
      <div className={styles.modalButton}>
        <Button onClick={handlePayment} type="submit">
          {t("adventure_extension.pay_here")}
        </Button>
      </div>
    </div>
  );
};

export default ExpiredModal;
