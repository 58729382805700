import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

// Localization
import { useTranslation } from "react-i18next";

// API
import { forgotPassword } from "@api";

// UI
import { TextField, AuthenticationForm } from "@components/common";

// Assets
import IconEmail from "@/assets/icons/icon-email.svg";

// Styles
import styles from "./ForgotPassword.module.css";

function noop() {}

export default function ForgotPassword({ setBackTo = noop }) {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);

  const defaultValues = {
    email: ""
  };
  const { handleSubmit, control, reset } = useForm({ defaultValues });

  const {
    mutate: forgotPasswordMutation,
    isLoading,
    isError
  } = useMutation(forgotPassword, {
    onSuccess: () => {
      setSuccess(true);
      reset(defaultValues);
    }
  });

  useEffect(() => {
    setBackTo("/login");
    return () => {
      setBackTo("..");
    };
  }, [setBackTo]);

  return (
    <AuthenticationForm
      title={t("login.forgot_password")}
      onSubmit={handleSubmit(forgotPasswordMutation)}
      submitText={t("login.send")}
      loading={isLoading}
      errorMessage={isError ? t("login.forgot_password_error") : null}
      successMessage={success ? t("login.forgot_password_instructions") : null}
      successButton={success ? t("login.back_to_start_button") : null}
      successTo={"/"}
      successIcon={IconEmail}
    >
      <TextField
        label={t("login.email")}
        type="email"
        name="email"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("login.email_required")
          }
        }}
      />
    </AuthenticationForm>
  );
}
