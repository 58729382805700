import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

// Localization
import { useTranslation, Trans } from "react-i18next";

// API
import { useAuth } from "@/auth.js";
import { signIn } from "@api";

// UI
import { TextField, AuthenticationForm, TextLink } from "@components/common";

// Styles
import styles from "./Login.module.css";

export default function Login() {
  const { t } = useTranslation();
  const { setIsLoggingIn, setIsLoggedIn, setUserRole } = useAuth();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
      password: ""
    }
  });
  const [error, setError] = useState(null);

  const {
    mutate: login,
    isLoading,
    isError
  } = useMutation(signIn, {
    onMutate: () => {
      setIsLoggingIn(true);
    },
    onSuccess: ({ data }) => {
      setUserRole(data.role);
      setIsLoggedIn(true);
      setIsLoggingIn(false);
    },
    onError: async (error) => {
      if (error.response) {
        const errorBody = await error.response.json();
        const errorMessage = errorBody.errors.join(", ");
        setError(errorMessage);
      }
    }
  });

  return (
    <AuthenticationForm
      title={t("login.login")}
      onSubmit={handleSubmit(login)}
      loading={isLoading}
      submitText={t("login.login_button")}
      errorMessage={isError ? error || t("login.login_error") : null}
      footer={
        <Trans i18nKey="login.no_account">
          <TextLink to="/register" />
        </Trans>
      }
    >
      <TextField
        label={t("login.email")}
        type="email"
        name="email"
        control={control}
        rules={{
          required: { value: true, message: t("login.email_required") }
        }}
      />
      <TextField
        label={t("login.password")}
        type="password"
        name="password"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("login.password_required")
          }
        }}
      />
      <div className={styles.forgotPassword}>
        <TextLink to="/forgot-password">{t("login.forgot_password")}</TextLink>
      </div>
    </AuthenticationForm>
  );
}
