import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth.js";

export default function Authenticated({ children }) {
  const { isLoggedIn, isLoggingIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggingIn && !isLoggedIn) {
      navigate("/");
    }
  }, [isLoggingIn, isLoggedIn, navigate]);

  if (isLoggedIn) {
    return children;
  } else {
    return null;
  }
}
