import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

// Localization
import { useTranslation, Trans } from "react-i18next";

// API
import { useAuth } from "@/auth.js";
import { signUp } from "@api";

// UI
import {
  TextField,
  TextLink,
  AuthenticationForm,
  CheckboxField
} from "@components/common";

// Styles
import styles from "./Register.module.css";

export default function Register() {
  const { t } = useTranslation();
  const { setIsLoggingIn, setIsLoggedIn } = useAuth();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
      acceptedTermsOfUse: false,
      acceptedMarketingConsent: false
    }
  });
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const {
    mutate: signUpMutation,
    isLoading,
    isError
  } = useMutation(signUp, {
    onMutate: () => {
      setIsLoggingIn(true);
    },
    onSuccess: (response) => {
      const { data: { email } = {} } = response;
      setIsLoggedIn(true);
      navigate("/welcome", {
        state: { email: email }
      });
      setIsLoggingIn(false);
    },
    onError: async (error) => {
      if (error.response) {
        const errorBody = await error.response.json();
        const errorMessage = errorBody.errors.full_messages.join(". ");
        setError(errorMessage);
      }
    }
  });

  return (
    <AuthenticationForm
      title={t("login.sign_up")}
      onSubmit={handleSubmit(signUpMutation)}
      submitText={t("login.sign_up_button")}
      loading={isLoading}
      errorMessage={isError ? error || t("login.sign_up_error") : null}
      footer={
        <Trans i18nKey="login.has_account">
          <TextLink to="/login" />
        </Trans>
      }
    >
      <TextField
        label={t("login.email")}
        type="email"
        name="email"
        control={control}
        rules={{
          required: { value: true, message: t("login.email_required") }
        }}
      />
      <TextField
        label={t("login.password")}
        type="password"
        name="password"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("login.password_required")
          }
        }}
      />
      <TextField
        label={t("login.password_confirmation")}
        type="password"
        name="passwordConfirmation"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("login.password_required")
          }
        }}
      />
      <CheckboxField
        label={
          <Trans i18nKey="login.accepted_terms_of_use">
            <TextLink href={t("common.terms_url ")} target="_blank" />
          </Trans>
        }
        name="acceptedTermsOfUse"
        control={control}
      />
      <CheckboxField
        label={
          <Trans i18nKey="login.accepted_marketing_consent">
            <TextLink href={t("common.privacy_url ")} target="_blank" />
          </Trans>
        }
        name="acceptedMarketingConsent"
        control={control}
      />
    </AuthenticationForm>
  );
}
