import cx from "classnames";

// Localization
import { useTranslation } from "react-i18next";

// UI
import { Loader } from "@components/common";

// Styles
import styles from "./Page.module.css";

export function Page({ top, bottom, children, color, loading, span }) {
  const { t } = useTranslation();

  return (
    <div className={styles.outer}>
      {loading ? (
        <div className={styles.empty}>
          <div>
            <Loader text={t("common.loading")} />
          </div>
        </div>
      ) : (
        <>
          {top && (
            <div
              className={cx(styles.top, {
                [styles.orange]: color === "orange",
                [styles.green]: color === "green"
              })}
            >
              {top}
            </div>
          )}
          <div className={cx(styles.bottom, { [styles.span]: span })}>
            {children}
          </div>
          {bottom && <PageFooter>{bottom}</PageFooter>}
        </>
      )}
    </div>
  );
}

export function PageFooter({ children }) {
  return (
    <div className={styles.footer}>
      <div className={styles.footerInner}>{children}</div>
    </div>
  );
}
