import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

// Localization
import i18n from "i18next";

// UI
import { Button, TopBar } from "@components/common";

// Components
import Welcome from "@features/Start/Welcome/Welcome";
import Login from "@features/Start/Login/Login";
import ForgotPassword from "@features/Start/ForgotPassword/ForgotPassword";
import NewPassword from "@features/Start/NewPassword/NewPassword";
import Register from "@features/Start/Register/Register";

// Assets
import { bgPhoto } from "@/siteConfig";

// Styles
import styles from "./Start.module.css";

export default function Start() {
  const { t } = useTranslation();
  const location = useLocation();
  const [backTo, setBackTo] = useState("..");
  const domain = window.location.hostname;
  const activeLanguage = i18n.language;

  let languages;
  if (domain.endsWith(".fi")) {
    languages = ["fi", "en", "sv"];
  } else if (domain.endsWith(".se")) {
    languages = ["sv", "fi", "en"];
  } else if (domain.endsWith(".com")) {
    languages = ["en", "fi", "sv"];
  } else {
    languages = ["fi", "en", "sv"];
  }

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  const BackgroundImage = () => {
    return (
      <div
        className={`${styles.bg} ${
          process.env.SITE === "mapiosa" && styles.bgLeft
        }`}
        style={{ backgroundImage: `url(${bgPhoto})` }}
      />
    );
  };

  return (
    <div className={styles.container}>
      <BackgroundImage />
      <div className={styles.inner}>
        <TopBar
          backTo={location.pathname !== "/" ? backTo : null}
          transparent
          right={
            <div className={styles.languageToggles}>
              {languages.map((lng) => (
                <Button
                  key={lng}
                  transparent
                  className={cx(styles.button, {
                    [styles.active]: activeLanguage === lng
                  })}
                  onClick={() => handleChangeLanguage(lng)}
                >
                  {t(`common.languages.${lng}`)}
                </Button>
              ))}
            </div>
          }
        />
        <div className={styles.content}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/forgot-password"
              element={<ForgotPassword setBackTo={setBackTo} />}
            />
            <Route path="/password" element={<NewPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Welcome />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
