import cx from "classnames";

// Styles
import styles from "./Text.module.css";

export function Text({
  element = "p",
  size = "base",
  weight = "normal",
  muted,
  className,
  children,
  ...otherProps
}) {
  const Component = element;
  return (
    <Component
      className={cx(
        styles.text,
        styles[size.toString()],
        styles[weight.toString()],
        { [styles.muted]: muted },
        className
      )}
      {...otherProps}
    >
      {children}
    </Component>
  );
}
