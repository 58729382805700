import cx from "classnames";

// Styles
import styles from "./IconBadge.module.css";

export function IconBadge({ icon, className, success, gray, danger }) {
  const IconComponent = icon;
  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.gray]: gray,
          [styles.success]: success,
          [styles.danger]: danger
        },
        className
      )}
    >
      <IconComponent className={styles.icon} />
    </div>
  );
}
