// UI
import { Page, H2, Text, CardList, CardListItem } from "@components/common";

// Localization
import { useTranslation } from "react-i18next";

// Assets
import IconLock from "@/assets/icons/icon-lock-small.svg";
import IconPin from "@/assets/icons/icon-pin-small.svg";

// Styles
import styles from "./AdventureSpots.module.css";

export default function AdventureSpots({
  currentLevel,
  points,
  levels,
  markerImages,
  numberOfSpots,
  onSpotClick
}) {
  const { t } = useTranslation();

  const progressWidth = (points / numberOfSpots) * 100 + "%";
  return (
    <Page
      color="orange"
      top={
        <>
          <H2 size="xxl" className="margin-16 margin-bottom">
            {t("adventure.spots.level")} {currentLevel}
          </H2>
          <H2 size="base" weight="semibold">
            {t("adventure.spots.completed_spots")}
          </H2>
          <div className={styles.progress}>
            <div
              className={styles.progressInner}
              style={{ width: progressWidth }}
            >
              <Text
                element="span"
                size="sm"
                weight="semibold"
                className={styles.progressLabel}
              >
                {points} / {numberOfSpots}
              </Text>
            </div>
          </div>
        </>
      }
    >
      <div className={styles.levels}>
        {levels?.length > 0
          ? levels.map((level) => (
              <Level
                key={level.id}
                level={level}
                markerImages={markerImages}
                onSpotClick={onSpotClick}
                currentLevel={currentLevel}
                points={points}
              />
            ))
          : null}
      </div>
    </Page>
  );
}

function Level({ level, markerImages, onSpotClick, currentLevel, points }) {
  const { t } = useTranslation();
  const { spots, levelNumber, name, pointsThreshold } = level;
  const title = `${t("adventure.spots.level")} ${levelNumber} ${
    name ? `(${name})` : ""
  }`;
  const spotsWithMarkerImages =
    spots?.map((spot) => {
      const markerImgSrc = markerImages.find((img) => {
        let spotStatus = "locked";
        if (spot.visited || spot.noProximityRequired) {
          spotStatus = "visited";
        }
        if (spot.completed) {
          spotStatus = "completed";
        }
        return img.markerIcon === spot.markerIcon && img.status === spotStatus;
      })?.url;
      return {
        ...spot,
        markerImgSrc
      };
    }) || [];

  const completedSpotsCount = spotsWithMarkerImages.filter(
    (spot) => spot.completed
  ).length;
  const totalSpotsCount = spotsWithMarkerImages.length;

  const levelLocked = currentLevel < level.levelNumber;
  const pointsToUnlock = pointsThreshold - points;

  return (
    <CardList
      title={title}
      subtitle={
        levelLocked
          ? t("adventure.spots.complete_to_unlock", {
              count: pointsToUnlock
            })
          : `${completedSpotsCount} / ${totalSpotsCount}`
      }
      subtitleIcon={levelLocked ? IconLock : IconPin}
      disabled={levelLocked}
    >
      {spotsWithMarkerImages?.length > 0
        ? spotsWithMarkerImages.map((spot) => {
            const { id, name, markerImgSrc, location, category } = spot;

            const handleOnClickSpot = () => {
              onSpotClick(location);
            };
            return (
              <CardListItem
                key={id}
                title={name}
                subtitle={category?.title}
                imgSrc={markerImgSrc}
                imgAlt={`${t("adventure.spots.icon_for_spot")} ${name}`}
                disabled={levelLocked}
                {...(!levelLocked && {
                  onClick: handleOnClickSpot
                })}
              />
            );
          })
        : null}
    </CardList>
  );
}
