import { Controller } from "react-hook-form";

// UI
import { Checkbox, CheckboxGroup } from "@components/common";

// Styles
import styles from "./CheckboxTask.module.css";

export default function CheckboxTask({ label, task, control, disabled }) {
  const { options } = task;

  return (
    <div className={styles.container}>
      <Controller
        name={"answer"}
        control={control}
        render={({ field: { onChange, value } }) => {
          // CheckboxGroup requires the values as array of strings so we have to convert them
          const valuesAsString = value?.map((val) => val?.toString());
          return (
            <CheckboxGroup
              value={valuesAsString}
              onChange={onChange}
              label={label}
            >
              {options.map((option) => (
                <Checkbox
                  key={option.id}
                  value={option.id}
                  isDisabled={disabled}
                >
                  {option.answer}
                </Checkbox>
              ))}
            </CheckboxGroup>
          );
        }}
      />
    </div>
  );
}
