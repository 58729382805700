import cx from "classnames";

// Localization
import { useTranslation } from "react-i18next";

// UI
import { Button, H3, Text } from "@components/common";

// Styles
import styles from "./Popup.module.css";

function noop() {}

export default function Popup({
  title,
  id,
  visited,
  levelNumber,
  completed,
  onSpotOpen = noop,
  category,
  unlocked
}) {
  const { t } = useTranslation();

  const handleOnClickOpenSpot = (id) => {
    onSpotOpen({ spotId: id, spotVisited: visited });
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.locked]: !unlocked,
        [styles.completed]: completed
      })}
    >
      <div className={styles.title}>
        <H3>{title}</H3>
        {category && (
          <Text size="sm" muted>
            {category} – {t("adventure.spots.level")} {levelNumber}
          </Text>
        )}
      </div>
      <div className={cx(styles.footer, { [styles.locked]: !unlocked })}>
        {unlocked ? (
          <Button fill small round onClick={() => handleOnClickOpenSpot(id)}>
            {t("adventure.spot.open_spot_button")}
          </Button>
        ) : (
          t("adventure.spot.come_closer")
        )}
      </div>
    </div>
  );
}
