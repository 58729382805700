import { useRef, useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax, import/no-unresolved
import "mapbox-gl/dist/mapbox-gl.css";

import distance from "@turf/distance";

// Components
import Popup from "./Popup/Popup";
import { Button } from "@components/common";
import Modal from "../common/Modal/Modal";

// Helpers
import { processSpotSourceData } from "./mapHelpers";

// Assets
import IconNavigate from "@/assets/icons/icon-navigate-small.svg";

// Styles
import styles from "./Map.module.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoidGhlY2l0eXNwb3R0aW5nIiwiYSI6ImNrdXFxbWdqZTE0cnoyb3FyZ2c3dmg5YmkifQ.nLFvmLsFudbl9wrkPSLp9w";

export default function Map({
  adventureCenterPoint,
  adventureZoomLevel,
  spots,
  onSpotOpen,
  markerImages,
  flyToDestination
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const popupRef = useRef(new mapboxgl.Popup({ offset: 15 }));
  const popupNodeRef = useRef(null);
  const geolocateRef = useRef(null);
  //const testMarkerRef = useRef(null);
  const [userLocation, setUserLocation] = useState();

  const [spotsWithinProximity, setSpotsWithinProximity] = useState([]);
  const [popupProps, setPopupProps] = useState(null);

  const startingLocation = adventureCenterPoint
    ?.trim()
    .split(",")
    .reverse()
    .map((coordinate) => parseFloat(coordinate));

  const checkProximityToSpots = useCallback(
    (longitude, latitude) => {
      const lockedSpots = spots.filter((spot) => {
        return !spot.visited && !spot.noProximityRequired;
      });

      const withinProximity = lockedSpots
        .filter((spot) => {
          const spotLngLat = spot.location
            .split(",")
            .reverse()
            .map((coordinate) => coordinate.trim());
          const distanceToSpot = distance(
            [longitude, latitude],
            [spotLngLat[0], spotLngLat[1]]
          );
          const distanceToSpotInMeters = distanceToSpot * 1000;
          return distanceToSpotInMeters < spot.proximity;
        })
        .map((spot) => parseInt(spot.id));

      setSpotsWithinProximity((oldSpots) => {
        if (
          withinProximity.length !== oldSpots.length ||
          !withinProximity.every((spot) => oldSpots.includes(spot))
        ) {
          return withinProximity;
        } else {
          return oldSpots;
        }
      });
    },
    [spots]
  );

  const handleSpotOpen = useCallback(
    (spotDetails) => {
      onSpotOpen(spotDetails);
      if (popupRef.current) {
        popupRef.current.remove();
      }
      map.current.setFeatureState(
        { source: "spot-radiuses", id: spotDetails.spotId },
        { active: false }
      );
    },
    [onSpotOpen]
  );

  const scale = new mapboxgl.ScaleControl({
    unit: 'metric'
  });

  useEffect(() => {
    if (map.current) return; // Initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: startingLocation,
      zoom: parseInt(adventureZoomLevel || 12)
    });

    map.current.addControl(scale);

    // Draggable marker for testing purposes
    // testMarkerRef.current = new mapboxgl.Marker({
    //   draggable: true
    // })
    //   .setLngLat(startingLocation)
    //   .addTo(map.current);

    // Add geolocate control to the map.
    geolocateRef.current = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      // When active the map will receive updates to the device's location as it changes.
      trackUserLocation: true,
      // Draw an arrow next to the location dot to indicate which direction the device is heading.
      showUserHeading: true
    });

    // Hack to disable automatic tracking pan of user location.
    geolocateRef.current._updateCamera = () => {};

    map.current.addControl(geolocateRef.current);

    map.current.on("load", () => {
      // This turn on the geolocate automatically on load and asks user permission for location if they haven't given it.
      geolocateRef.current.trigger();

      // Map spot coordinates and basic info as geojson feature data
      const { spotData, spotRadiusData } = processSpotSourceData(spots);

      // Add a data source containing one point feature.
      map.current.addSource("spots", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: spotData
        }
      });

      map.current.addSource("spot-radiuses", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: spotRadiusData
        }
      });
      // We first wait for all images to be loaded and added to the map source before adding layers
      Promise.all(
        markerImages.map(
          (img) =>
            new Promise((resolve, _reject) => {
              map.current.loadImage(img.url, (error, res) => {
                if (error) throw error;
                map.current?.addImage(img.id, res);
                resolve();
              });
            })
        )
      ).then(() => {
        // Proximity radius circle fill
        map.current?.addLayer({
          id: "spot-radius-fill",
          type: "fill",
          source: "spot-radiuses",
          paint: {
            "fill-color": "#EE764A",
            "fill-opacity": [
              "case",
              ["boolean", ["feature-state", "active"], false],
              0.2,
              0
            ]
          }
        });
        // Proximity radius circle border
        map.current?.addLayer({
          id: "spot-radius-border",
          type: "line",
          source: "spot-radiuses",
          paint: {
            "line-color": "#EE764A",
            "line-width": 2,
            "line-opacity": [
              "case",
              ["boolean", ["feature-state", "active"], false],
              0.8,
              0
            ]
          }
        });
        // Map marker images
        map.current?.addLayer({
          id: "spot-markers",
          type: "symbol",
          source: "spots",
          layout: {
            "icon-image": ["image", ["get", "icon"]],
            "icon-size": 0.5,
            "icon-allow-overlap": true
          }
        });
      });
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.current.on("mouseenter", "spot-markers", () => {
      map.current.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    map.current.on("mouseleave", "spot-markers", () => {
      map.current.getCanvas().style.cursor = "";
    });

    geolocateRef.current.on("geolocate", (event) => {
      const location = [event.coords.longitude, event.coords.latitude];
      setUserLocation(location);
    });

    return () => {
      map.current.remove();
      map.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!map.current) return; // Need to check this before trying to manipulate layer

    let clickedStateId = null;
    map.current.on("click", (event) => {
      // If the user clicked on markers, get its information.
      const features = map.current.queryRenderedFeatures(event.point, {
        layers: ["spot-markers"]
      });

      if (!features.length) {
        // If a feature was not clicked reset the radius state if there was one
        if (clickedStateId !== null) {
          map.current.setFeatureState(
            { source: "spot-radiuses", id: clickedStateId },
            { active: false }
          );
        }
        return;
      }

      // Basic spot info
      const spot = features[0];
      const coordinates = spot.geometry.coordinates.slice();
      const visited = spot.properties.visited;
      const noProximityRequired = spot.properties.noProximityRequired;
      const id = spot.id;

      while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Create popup node for react popup

      // If We are within proximity or the spot is previously visited then the spot is unlocked
      const unlocked =
        noProximityRequired ||
        spotsWithinProximity.includes(parseInt(id)) ||
        visited;

      popupRef.current
        .setLngLat(coordinates)
        .setDOMContent(popupNodeRef.current)
        .addTo(map.current);

      setPopupProps({
        name: spot.properties.name,
        category: spot.properties.category,
        levelNumber: spot.properties.levelNumber,
        completed: spot.properties.completed,
        visited: visited,
        noProximityRequired: noProximityRequired,
        id: id
      });

      // First reset any previous radius layer states
      if (clickedStateId !== null) {
        map.current.setFeatureState(
          { source: "spot-radiuses", id: clickedStateId },
          { active: false }
        );
      }
      // Then set radius layer state if the clicked spot was a locked one.
      if (!unlocked) {
        clickedStateId = id;
        map.current.setFeatureState(
          { source: "spot-radiuses", id: clickedStateId },
          { active: true }
        );
      }
    });
  }, [map, spotsWithinProximity, onSpotOpen]);

  useEffect(() => {
    if (!popupProps) return;

    const unlocked =
      popupProps.noProximityRequired ||
      spotsWithinProximity.includes(parseInt(popupProps.id)) ||
      popupProps.visited;

    ReactDOM.render(
      <Popup
        title={popupProps.name}
        category={popupProps.category}
        levelNumber={popupProps.levelNumber}
        id={popupProps.id}
        visited={popupProps.visited}
        completed={popupProps.completed}
        onSpotOpen={handleSpotOpen}
        unlocked={unlocked}
      />,
      popupNodeRef.current
    );
  }, [popupProps, spotsWithinProximity, handleSpotOpen]);

  useEffect(() => {
    // This useEffect updates the layer source data whenever spots change so the map knows to redraw the layer. It also updates the marker to completed whenever a spot gets completed.
    if (!map.current.isStyleLoaded()) return; // Need to check this before trying to manipulate source

    const { spotData } = processSpotSourceData(spots);

    // Here we set new data for the layer source
    map.current?.getSource("spots")?.setData({
      type: "FeatureCollection",
      features: spotData
    });

    // Here we set set layerProperties to change the icon for completed spots.
    const completedSpots = spots
      .filter((spot) => {
        return spot.completed;
      })
      .map((spot) => parseInt(spot.id));

    map.current.on("idle", () => {
      map.current?.setLayoutProperty("spot-markers", "icon-image", [
        "match",
        ["id"],
        [...completedSpots, 0],
        ["image", ["get", "icon-completed"]], // Icon name to change when in proximity
        ["image", ["get", "icon"]] // Locked icon
      ]);
    });
  }, [spots]);

  useEffect(() => {
    // This useEffect add and updates the event listener whenever the spots change since locked spots can change and we only need to check against them.
    if (!map.current) return; // Need to check this before trying to manipulate source

    const updateSpotsInProximity = (position) => {
      const userLong = position.coords.longitude;
      const userLat = position.coords.latitude;
      checkProximityToSpots(userLong, userLat);
    };

    // const updateSpotsInProximityTestMarker = () => {
    //   const markerLocation = testMarkerRef.current.getLngLat();
    //   checkProximityToSpots(markerLocation.lng, markerLocation.lat);
    // };

    // Testing marker drag event
    geolocateRef.current.on("geolocate", updateSpotsInProximity);
    //testMarkerRef.current.on("dragend", updateSpotsInProximityTestMarker);
    return () => {
      geolocateRef.current.off("geolocate", updateSpotsInProximity);
      //testMarkerRef.current.off("dragend", updateSpotsInProximityTestMarker);
    };
  }, [checkProximityToSpots]);

  useEffect(() => {
    // This useEffect updates locked spot markers to visited spots markers when in prxoximity
    if (!map.current) return; // Need to check this before trying to manipulate source
    if (!map.current.isStyleLoaded()) return; // Need to check this before trying to manipulate source

    map.current.on("idle", () => {
      map.current.setLayoutProperty("spot-markers", "icon-image", [
        "match",
        ["id"],
        [...spotsWithinProximity, 0],
        ["image", ["get", "icon-visited"]], // Icon name to change when in proximity
        ["image", ["get", "icon"]] // Locked icon
      ]);
    });
  }, [spotsWithinProximity, userLocation]);

  useEffect(() => {
    // This useEffect updates locked spot markers to visited spots markers when in prxoximity
    if (!map.current) return; // Need to check this before trying to manipulate source
    if (!flyToDestination) return; // Need to check this before trying to manipulate source
    map.current.flyTo({
      center: flyToDestination,
      zoom: 15
    });
  }, [flyToDestination]);

  const handleOnClickLocateUser = () => {
    map.current.flyTo({
      center: userLocation,
      zoom: 15
    });
  };
  return (
    <div>
      <div ref={mapContainer} className={styles.map} />
      <div ref={popupNodeRef} />
      {userLocation && (
        <Button
          onClick={handleOnClickLocateUser}
          round
          white
          className={styles.button}
        >
          <IconNavigate className={styles.buttonIcon} />
        </Button>
      )}
    </div>
  );
}
