import { Controller } from "react-hook-form";
import cx from "classnames";

// Styles
import styles from "./TextField.module.css";

export function TextField({
  label,
  as,
  type,
  name,
  control,
  large,
  disabled,
  placeholder,
  ...otherProps
}) {
  const InputComponent = as || "input";
  const textAreaProps = {
    rows: 4
  };
  return (
    <Controller
      control={control}
      name={name}
      {...otherProps}
      render={({ field, fieldState }) => {
        return (
          <div className={styles.container}>
            {label && (
              <label htmlFor={name} className={styles.label}>
                {label}
              </label>
            )}
            <InputComponent
              className={cx(styles.input, {
                [styles.large]: large,
                [styles.textarea]: as === "textarea"
              })}
              type={type}
              id={name}
              disabled={disabled}
              placeholder={placeholder}
              {...textAreaProps}
              {...field}
            />
            {fieldState.error && (
              <div className={styles.error}>{fieldState.error.message}</div>
            )}
          </div>
        );
      }}
    />
  );
}
