import React, { useMemo } from "react";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";

// Localization
import { useTranslation } from "react-i18next";

// UI
import {
  Button,
  H1,
  Text,
  Page,
  CardList,
  CardListItem,
  CenteredContent
} from "@components/common";

// Assets
import IconPin from "@/assets/icons/icon-pin-radius.svg";

// Styles
import styles from "./AdventureNotifications.module.css";

export default function AdventureNotifications({ levels, spots }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const completedSpots = useMemo(
    () => spots?.filter((spot) => spot.completed) || [],
    [spots]
  ).sort((a, b) => {
    const bCompletedAt = parseISO(b.completedAt);
    const aCompletedAt = parseISO(a.completedAt);

    return bCompletedAt - aCompletedAt;
  });

  const handleOnClickSpot = (id) => {
    navigate(`../spot/${id}/reward`, {
      state: { backUrl: -1 }
    });
  };
  const handleOnClickLevel = (levelId) => {
    navigate(`../level/level-reward`, {
      state: { levelId: levelId, backUrl: -1 }
    });
  };

  return (
    <Page
      top={
        completedSpots.length > 0 && (
          <>
            <H1 size="xxl" className="margin-8 margin-bottom">
              {t("adventure.notifications.title")}
            </H1>
            <Text>{t("adventure.notifications.intro")}</Text>
          </>
        )
      }
    >
      {completedSpots.length > 0 ? (
        <CardList className={styles.cardList}>
          {completedSpots.map((spot) => {
            const { id, name, completedAt } = spot;
            const spotCompletionDate = format(
              parseISO(completedAt),
              "d.M.yyyy"
            );

            // Spots are rendered in reverse chronological order (newest top) so need to unreverse the array to check for level up
            const reverseIndex = completedSpots
              .reduceRight((accumulator, currentSpot) => {
                accumulator.push(currentSpot.id);
                return accumulator;
              }, [])
              .indexOf(id);

            const levelUp = levels.find(
              (level) =>
                level.pointsThreshold !== 0 &&
                level.pointsThreshold === reverseIndex + 1
            );

            return (
              <React.Fragment key={id}>
                {levelUp && (
                  <CardListItem
                    onClick={() => handleOnClickLevel(levelUp)}
                    title={t("adventure.notifications.level_up", {
                      level: levelUp.levelNumber
                    })}
                    timelineItem
                    timelineEvent
                  />
                )}
                <CardListItem
                  title={name}
                  subtitle={spotCompletionDate}
                  onClick={() => handleOnClickSpot(id)}
                  timelineItem
                />
              </React.Fragment>
            );
          })}
        </CardList>
      ) : (
        <CenteredContent
          gray
          icon={IconPin}
          title={t("adventure.notifications.no_completions_title")}
          text={t("adventure.notifications.no_completions_paragraph")}
        >
          <Button large to={".."}>
            {t("adventure.notifications.show_map")}
          </Button>
        </CenteredContent>
      )}
    </Page>
  );
}

function Spot({ id, name, spotCompletionDate, onClick, levelUp }) {
  const { t } = useTranslation();

  return (
    <React.Fragment key={id}>
      <CardListItem
        title={name}
        subtitle={spotCompletionDate}
        onClick={() => onClick(id)}
        timelineItem
      />
      {levelUp && (
        <CardListItem
          title={t("adventure.notifications.level_up", {
            level: levelUp.levelNumber
          })}
          timelineItem
          timelineEvent
        />
      )}
    </React.Fragment>
  );
}
