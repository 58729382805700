import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

// Localization
import { useTranslation } from "react-i18next";

// API
import { changePassword } from "@api";

// UI
import {
  NotificationInline,
  Button,
  TextField,
  TopBar
} from "@components/common";

// Styles
import styles from "./ChangePassword.module.css";

export default function ChangePassword() {
  const { t } = useTranslation();
  const defaultValues = {
    password: "",
    passwordConfirmation: ""
  };
  const { handleSubmit, control, reset } = useForm({
    defaultValues
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const {
    mutate: setNewPasswordMutation,
    isError,
    isLoading
  } = useMutation(changePassword, {
    onSuccess: () => {
      reset(defaultValues);
      setSuccess(true);
    },
    onError: async (error) => {
      if (error.response) {
        const errorBody = await error.response.json();
        const errorMessage = errorBody.errors.full_messages.join(". ");
        setError(errorMessage);
      }
    }
  });

  return (
    <div className={styles.wrapper}>
      <TopBar title={t("settings.change_password")} backTo={"/settings"} />
      <div className={styles.content}>
        <div className={styles.inner}>
          <form
            onSubmit={handleSubmit(setNewPasswordMutation)}
            className="child-margins-y-16"
          >
            <TextField
              label={t("settings.new_password")}
              type="password"
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `${t("settings.new_password")} ${t(
                    "common.required"
                  )}`
                }
              }}
            />
            <TextField
              label={t("settings.new_password_confirm")}
              type="password"
              name="passwordConfirmation"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `${t("settings.new_password_confirm_required")}`
                }
              }}
            />
            {success && (
              <NotificationInline
                success
                message={t("settings.change_password_success")}
              />
            )}
            {isError && (
              <NotificationInline
                message={error ? error : t("settings.change_password_error")}
              />
            )}
            <Button type="submit" loading={isLoading} fill large>
              {t("settings.change_password_button")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
