import React from "react";
import { VisuallyHidden, useVisuallyHidden } from "@react-aria/visually-hidden";
import { useCheckboxGroup, useCheckboxGroupItem } from "@react-aria/checkbox";
import { useCheckboxGroupState } from "@react-stately/checkbox";
import cx from "classnames";

// Styles
import styles from "./Checkbox.module.css";

// Context
const CheckboxGroupContext = React.createContext(null);

export function CheckboxGroup(props) {
  let { children, label } = props;
  let state = useCheckboxGroupState(props);
  let { groupProps, labelProps } = useCheckboxGroup(props, state);

  return (
    <div {...groupProps} className={styles.group}>
      <VisuallyHidden>
        <span {...labelProps}>{label}</span>
      </VisuallyHidden>
      <CheckboxGroupContext.Provider value={state}>
        {children}
      </CheckboxGroupContext.Provider>
    </div>
  );
}

export function Checkbox(props) {
  let { children } = props;
  let state = React.useContext(CheckboxGroupContext);
  let ref = React.useRef();
  let { inputProps } = useCheckboxGroupItem(props, state, ref);

  let isDisabled = state.isDisabled || props.isDisabled;
  let isSelected = state.isSelected(props.value);

  const { visuallyHiddenProps } = useVisuallyHidden();
  return (
    <label
      className={cx(styles.labelContainer, {
        [styles.checked]: isSelected,
        [styles.disabled]: isDisabled
      })}
    >
      <input
        className={styles.input}
        {...visuallyHiddenProps}
        {...inputProps}
      />
      <div className={styles.indicator}>
        <span aria-hidden className={styles.indicatorTick} />
      </div>

      <span className={styles.labelText}>{children}</span>
    </label>
  );
}
