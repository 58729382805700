import circle from "@turf/circle";

export function processSpotSourceData(spots) {
  const spotData = spots.map((spot) => {
    const spotLngLat = spot.location
      .split(",")
      .reverse()
      .map((coordinate) => parseFloat(coordinate.trim()));

    let status = "locked";

    if (spot.visited || spot.noProximityRequired) {
      status = "visited";
    }
    if (spot.completed) {
      status = "completed";
    }

    const markerIcon = spot.markerIcon?.replace(/_/g, "-") || "favourite";

    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: spotLngLat
      },
      properties: {
        icon: `pin-${status}-${markerIcon}`,
        "icon-locked": `pin-locked-${markerIcon}`,
        "icon-visited": `pin-visited-${markerIcon}`,
        "icon-completed": `pin-completed-${markerIcon}`,
        name: spot.name,
        category: spot.category?.title || "",
        levelNumber: spot.levelNumber,
        completed: spot.completed,
        visited: spot.visited,
        noProximityRequired: spot.noProximityRequired,
        proximity: spot.proximity
      },
      id: spot.id
    };
  });

  const spotRadiusData = spotData.map((spot) => {
    const coordinates = spot.geometry.coordinates;
    const proximity = spot.properties.proximity / 1000;
    const spotRadius = {
      ...circle(coordinates, proximity, { steps: 80 }),
      id: spot.id
    };
    return spotRadius;
  });

  return { spotData, spotRadiusData };
}
