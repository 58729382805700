import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

// Localization
import { useTranslation } from "react-i18next";

// API
import { activateAdventure } from "@api";

// UI
import {
  NotificationInline,
  Button,
  TextField,
  TopBar,
  CenteredContent,
  TextLink
} from "@components/common";

// Assets
import IconKey from "@/assets/icons/icon-key.svg";

// Styles
import styles from "./Activate.module.css";

export default function Activate() {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const {
    mutate: activate,
    isError,
    isLoading
  } = useMutation(activateAdventure, {
    onSuccess: () => {
      navigate("/adventures", {
        state: { successMessage: t("activate.activation_successful") }
      });
    },
    onError: async (error) => {
      if (error.response) {
        const errorBody = await error.response.json();
        setError(errorBody.message);
      } else {
        setError("generic");
      }
    }
  });

  const ErrorContent = () => {
    if (process.env.SITE === "mapiosa") {
      return (
        <div>
          <span>{t(`activate.error.${error}`)}</span> <br></br> <br></br>
          <span>{t("activate.error.get_help_via_email")}</span> <br></br>{" "}
        </div>
      );
    } else {
      return (
        <div>
          <span>{t(`activate.error.${error}`)}</span> <br></br>{" "}
          <TextLink className={styles.link} href={t("common.gift_card_url ")}>
            {t("activate.error.check_instruction_link")}
          </TextLink>
        </div>
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <TopBar title={t("activate.title")} backTo={"/adventures"} />
      <div className={styles.content}>
        <CenteredContent
          icon={IconKey}
          title={t("activate.title")}
          text={t("activate.instructions")}
        >
          <form onSubmit={handleSubmit(activate)}>
            <TextField
              label={t("activate.code_label")}
              type="text"
              name="code"
              control={control}
              large
              placeholder={t("activate.input_placeholder")}
              rules={{
                required: {
                  value: true,
                  message: `${t("activate.code_label")} ${t("common.required")}`
                }
              }}
            />
            {isError && (
              <NotificationInline
                message={
                  error ? (
                    error == "invalid_code" ? (
                      <ErrorContent />
                    ) : (
                      t(`activate.error.${error}`)
                    )
                  ) : (
                    t("activate.error.generic")
                  )
                }
                className={"margin-16 margin-top"}
              />
            )}
            <div className="margin-16 margin-top">
              <Button
                loading={isLoading}
                loadingText={t("activate.activate_loading")}
                type="submit"
                large
                fill
              >
                {t("activate.activate_button")}
              </Button>
            </div>
          </form>
        </CenteredContent>
      </div>
    </div>
  );
}
