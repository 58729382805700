import cx from "classnames";

// UI
import { H2, H3, Text } from "@components/common";

// Assets
import { ChevronRight } from "react-feather";

// Styles
import styles from "./CardList.module.css";

export function CardList({
  children,
  title,
  subtitle,
  subtitleIcon,
  disabled,
  className
}) {
  const IconComponent = subtitleIcon;
  return (
    <div
      className={cx(
        styles.list,
        { [styles.listDisabled]: disabled },
        className
      )}
    >
      {title && (
        <div className={styles.listTop}>
          <H2 size={"base"}>{title}</H2>
          {subtitle && (
            <Text element="div" size="sm" className={styles.listSubtitle}>
              {subtitleIcon && (
                <IconComponent className={styles.listSubtitleIcon} />
              )}
              {subtitle}
            </Text>
          )}
        </div>
      )}
      <div className={styles.listCard}>{children}</div>
    </div>
  );
}

export function CardListItem({
  onClick,
  title,
  subtitle,
  imgSrc,
  imgAlt,
  disabled,
  timelineItem,
  timelineEvent,
  ...otherProps
}) {
  const Wrapper = onClick ? "button" : "div";
  return (
    <Wrapper
      className={cx(styles.item, {
        [styles.itemDisabled]: disabled,
        [styles.timelineEvent]: timelineEvent
      })}
      onClick={onClick}
      {...otherProps}
    >
      {imgSrc && <img src={imgSrc} alt={imgAlt} className={styles.itemImg} />}
      {timelineItem && <div className={styles.itemBullet}></div>}
      <div className={styles.itemContent}>
        <div className={styles.itemContentInner}>
          <H3 size={"base"} weight="semibold">
            {title}
          </H3>
          <Text size="sm" className={styles.itemSubtitle}>
            {subtitle}
          </Text>
        </div>
        {onClick && <ChevronRight className={styles.itemChevron} />}
      </div>
    </Wrapper>
  );
}
