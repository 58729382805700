import React from "react";
import cx from "classnames";

// Styles
import styles from "./NotificationInline.module.css";

export function NotificationInline({ success = false, message, className }) {
  return (
    <div
      className={cx(styles.container, { [styles.success]: success }, className)}
    >
      {message}
    </div>
  );
}
