import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import ky from "ky";

// Localization
import { useTranslation } from "react-i18next";

// UI
import { AuthenticationForm, TextField } from "@components/common";

// Styles
import styles from "./NewPassword.module.css";

export default function NewPassword() {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      password: "",
      passwordConfirmation: ""
    }
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();

  // Custom request for resetting the password since we need to use headers from the url for this request
  async function resetPassword({ password, passwordConfirmation }) {
    return await ky
      .extend({
        prefixUrl: "/api/",
        retry: {
          limit: 0
        },
        hooks: {
          beforeRequest: [
            (request) => {
              const params = new URLSearchParams(searchParams);

              if (params) {
                request.headers.set("access-token", params.get("access-token"));
                request.headers.set("uid", params.get("uid"));
                request.headers.set("client", params.get("client"));
              }
            }
          ]
        }
      })
      .put("auth/password", {
        json: {
          password,
          password_confirmation: passwordConfirmation
        }
      })
      .json();
  }

  const {
    mutate: resetPasswordMutation,
    isLoading,
    isError
  } = useMutation(resetPassword, {
    onSuccess: () => {
      setSuccess(true);
    },
    onError: async (error) => {
      if (error.response) {
        const errorBody = await error.response.json();
        const errorMessage = errorBody.errors.full_messages.join(". ");
        setError(errorMessage);
      }
    }
  });

  return (
    <AuthenticationForm
      title={t("login.new_password")}
      onSubmit={handleSubmit(resetPasswordMutation)}
      submitText={t("login.set")}
      loading={isLoading}
      errorMessage={isError ? error || t("login.new_password_error") : null}
      successMessage={success ? t("login.new_password_success_message") : null}
      successButton={success ? t("login.login") : null}
      successTo={"/login"}
    >
      <TextField
        label={t("login.password")}
        type="password"
        name="password"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("login.password_required")
          }
        }}
      />
      <TextField
        label={t("login.password_confirmation")}
        type="password"
        name="passwordConfirmation"
        control={control}
        rules={{
          required: {
            value: true,
            message: t("login.password_required")
          }
        }}
      />
    </AuthenticationForm>
  );
}
