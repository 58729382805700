import { Link } from "react-router-dom";
import cx from "classnames";
import { format, parseISO } from "date-fns";

// Localization
import { useTranslation, Trans } from "react-i18next";

// UI
import { H2, Text } from "@components/common";

// Assets
import { ChevronRight } from "react-feather";
import IconPin from "@/assets/icons/icon-pin-small.svg";

// Styles
import styles from "./AdventureCard.module.css";

export function AdventureCard({
  title,
  to,
  id,
  coverImage,
  currentLevel,
  points,
  numberOfSpots,
  accessEndDate,
  locked,
  expired,
  handleExpiredModal,
  extendDuration,
  extensionProductSite,
  extensionTextFi,
  extensionTextEn,
  extensionTextSv,
  showExtensionModal,
  notExtensible
}) {
  const { t } = useTranslation();
  const Element = locked || expired ? "div" : Link;

  return (
    <Element
      {...(!locked && { to: to })}
      className={cx(styles.card, {
        [styles.locked]: locked || expired
      })}
      style={{ backgroundImage: `url(${coverImage})` }}
    >
      {locked && expired ? (
        <Text size="lg" className={styles.lockedBanner}>
          {t("adventure_list.adventure_locked")}
        </Text>
      ) : notExtensible === true && expired === true ? (
        <Text
          size="lg"
          className={styles.lockedBanner}
          style={{ textAlign: "center" }}
        >
          {t("adventure_extension.adventure_expired")}
        </Text>
      ) : expired ? (
        <Text
          size="lg"
          className={styles.lockedBanner}
          onClick={() =>
            handleExpiredModal({
              id,
              accessEndDate,
              extendDuration,
              extensionProductSite,
              extensionTextFi,
              extensionTextEn,
              extensionTextSv,
              showExtensionModal
            })
          }
          style={{ textAlign: "center", cursor: "pointer" }}
        >
          {t("adventure_extension.adventure_expired")}
          <br />
          {t("adventure_extension.adventure_expired_bottom")}
        </Text>
      ) : (
        locked && (
          <Text size="lg" className={styles.lockedBanner}>
            {t("adventure_list.adventure_locked")}
          </Text>
        )
      )}
      <div className={styles.level}>
        <Trans i18nKey="adventure_list.level" values={{ level: currentLevel }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <span className={styles.levelNumber} />
        </Trans>
      </div>
      <div className={styles.points}>
        <IconPin className={styles.pointsIcon} />
        <span className={styles.pointsText}>
          {points} / {numberOfSpots}
        </span>
      </div>
      <Footer title={title} accessEndDate={accessEndDate} />
      <Footer title={title} accessEndDate={accessEndDate} mask>
        <div
          className={styles.maskBlur}
          style={{ backgroundImage: `url(${coverImage})` }}
        />
      </Footer>
    </Element>
  );
}

function Footer({ title, accessEndDate, mask, children }) {
  const { t } = useTranslation();
  const accessEndDateFormatted = format(parseISO(accessEndDate), "d.M.yyyy");
  return (
    <div className={cx(styles.footer, { [styles.mask]: mask })}>
      {children}
      <div className={styles.footerContent}>
        <H2 size="lg">{title}</H2>
        <Text size="sm">
          {t("adventure_list.valid_until", { date: accessEndDateFormatted })}
        </Text>
      </div>
      <ChevronRight className={styles.footerIcon} />
    </div>
  );
}
