import { Link } from "react-router-dom";
import cx from "classnames";

// UI
import { H2 } from "@components/common";
import { ChevronRight } from "react-feather";

// Styles
import styles from "./ListNav.module.css";

export function ListNav({ title, children }) {
  return (
    <div className={styles.container}>
      <H2 size="lg" className={styles.title}>
        {title}
      </H2>
      <nav className={styles.nav}>{children}</nav>
    </div>
  );
}
export function ListNavItem({
  icon,
  to,
  children,
  danger,
  active,
  rightIcon = ChevronRight,
  className,
  ...otherProps
}) {
  const Wrapper = to ? Link : "button";
  const IconComponent = icon;
  const RightIcon = rightIcon ? rightIcon : "div";
  return (
    <Wrapper
      className={cx(
        styles.item,
        {
          [styles.danger]: danger,
          [styles.active]: active
        },
        className
      )}
      to={to}
      {...otherProps}
    >
      {icon && <IconComponent className={styles.itemIcon} />}
      <span className={styles.itemText}>{children}</span>
      <RightIcon className={styles.itemChevron} />
    </Wrapper>
  );
}
