import { Link } from "react-router-dom";
import cx from "classnames";

// Styles
import styles from "./TextLink.module.css";

export function TextLink({ children, className, to, ...props }) {
  const Wrapper = to ? Link : "a";
  return (
    <Wrapper className={cx(styles.base, className)} to={to} {...props}>
      {children}
    </Wrapper>
  );
}
