import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

// Styles
import styles from "../Start/Start.module.css";
import Start from "@features/Start/Start";

// API
import { verifyVisitor } from "@api";
import { useAuth } from "@/auth.js";

export default function VisitorAdventure() {
  const { setIsLoggingIn, setIsLoggedIn } = useAuth();
  const { code } = useParams(); // Extract the code from the URL
  const [error, setError] = useState(null);

  const { mutate: verifyAndProceed } = useMutation(verifyVisitor, {
    onMutate: () => {
      setIsLoggingIn(true);
    },
    onSuccess: (response) => {
      const { id: adventureId } = response.data;
      if (adventureId) {
        localStorage.setItem("redirectPath", "/adventure/" + adventureId);
      }
      setIsLoggedIn(true);
      setIsLoggingIn(false);
    },
    onError: async (error) => {
      setIsLoggingIn(false);
      if (error.response) {
        const errorBody = await error.response.json();
        console.log("errorBody", errorBody);
        setError(errorBody.message);
      } else {
        setError("generic");
      }
    }
  });

  useEffect(() => {
    if (code) {
      verifyAndProceed({ code });
    }
  }, [code]);

  return (
    <div className={styles.container}>
      <Start />
    </div>
  );
}
