import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

// Localization
import i18n from "i18next";
import { useTranslation } from "react-i18next";

// Auth
import { useAuth } from "@/auth.js";

// API
import { signOut } from "@api";

// UI
import { ListNav, ListNavItem, TopBar } from "@components/common";
import { Lock, LogOut, Check } from "react-feather";

// Styles
import styles from "./Settings.module.css";

export default function Settings() {
  const { t } = useTranslation();

  const { _isLoggedIn, setIsLoggedIn, userRole } = useAuth();
  const navigate = useNavigate();

  const { mutate: logout } = useMutation(signOut, {
    onSuccess: (response) => {
      if (response.success) {
        setIsLoggedIn(false);
        navigate("/login");
      }
    }
  });

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  const activeLanguage = i18n.language;

  return (
    <div className={styles.wrapper}>
      <TopBar title={t("settings.settings")} backTo={"/adventures"} />
      <div className={styles.content}>
        <ListNav title={t("settings.language")}>
          <ListNavItem
            onClick={() => handleChangeLanguage("en")}
            active={activeLanguage === "en"}
            rightIcon={activeLanguage === "en" ? Check : null}
          >
            {t("common.languages.en")}
          </ListNavItem>
          <ListNavItem
            onClick={() => handleChangeLanguage("fi")}
            active={activeLanguage === "fi"}
            rightIcon={activeLanguage === "fi" ? Check : null}
          >
            {t("common.languages.fi")}
          </ListNavItem>
          <ListNavItem
            onClick={() => handleChangeLanguage("sv")}
            active={activeLanguage === "sv"}
            rightIcon={activeLanguage === "sv" ? Check : null}
          >
            {t("common.languages.sv")}
          </ListNavItem>
        </ListNav>
        <ListNav title={t("settings.account")}>
          {userRole === "visitor" ? (
            <ListNavItem icon={LogOut} onClick={logout} danger>
              {t("settings.end_session")}
            </ListNavItem>
          ) : (
            <>
              <ListNavItem icon={Lock} to="/change-password">
                {t("settings.change_password")}
              </ListNavItem>
              <ListNavItem icon={LogOut} onClick={logout} danger>
                {t("settings.log_out")}
              </ListNavItem>
            </>
          )}
        </ListNav>
      </div>
    </div>
  );
}
