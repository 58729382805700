import cx from "classnames";

// Styles
import styles from "./Loader.module.css";

export function Loader({ text, size = "base", color = "action", inline }) {
  return (
    <div
      className={cx(styles.container, {
        [styles.sizeSmall]: size === "sm",
        [styles.colorWhite]: color === "white",
        [styles.colorGray]: color === "gray",
        [styles.inline]: inline
      })}
    >
      <div
        className={cx(styles.spinner, { [styles.sizeSmall]: size === "sm" })}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {text && <div className={styles.text}>{text}</div>}
    </div>
  );
}
