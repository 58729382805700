// UI
import { TextField } from "@components/common";

// Styles
import styles from "./OpenTask.module.css";

// Localization
import { useTranslation } from "react-i18next";

export default function OpenTask({ control, disabled }) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <TextField
        type="textarea"
        as="textarea"
        name="answer"
        disabled={disabled}
        control={control}
        placeholder={t("adventure.spot.open_question_input_placeholder")}
        rows="5"
      />
    </div>
  );
}
