import { useState } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { format, parseISO } from "date-fns";

// Localization
import { useTranslation, Trans } from "react-i18next";

// Api
import { getSpot, completeSpot } from "@api";
import { useAuthQuery, useMutation } from "@hooks";
import { useQueryClient } from "react-query";

// UI
import {
  Page,
  PageFooter,
  Button,
  H1,
  H2,
  H3,
  RichText,
  TopBar,
  Text,
  ErrorBoundary
} from "@components/common";
import { X } from "react-feather";

// Components
import CheckboxTask from "./CheckboxTask/CheckboxTask";
import RadioTask from "./RadioTask/RadioTask";
import TextTask from "./TextTask/TextTask";
import OpenTask from "./OpenTask/OpenTask";
import Reward from "./Reward/Reward";
import NewLevelReward from "./Reward/NewLevelReward";

// Styles
import styles from "./Spot.module.css";

export default function Spot({ currentLevel }) {
  const { adventureId, spotId } = useParams();
  const { data: spot, isLoading } = useAuthQuery(
    ["spot", { adventureId: adventureId, spotId: spotId }],
    getSpot
  );

  return (
    <>
      <Routes>
        <Route
          path=""
          element={
            <SpotContent
              loading={isLoading}
              spot={spot}
              adventureId={adventureId}
            />
          }
        />
        <Route
          path="reward"
          element={
            <Reward
              loading={isLoading}
              spot={spot}
              currentLevel={currentLevel}
            />
          }
        />
        <Route
          path="reward-level"
          element={
            <NewLevelReward
              loading={isLoading}
              spot={spot}
              currentLevel={currentLevel}
            />
          }
        />
      </Routes>
    </>
  );
}

function SpotContent({ loading, spot, adventureId }) {
  return (
    <Page loading={loading}>
      <TopBar
        transparent
        absolute
        right={<Button to={"../.."} icon={X} round white />}
      />
      <ErrorBoundary>
        <SpotForm spot={spot} adventureId={adventureId} />
      </ErrorBoundary>
    </Page>
  );
}

function SpotForm({ spot, adventureId }) {
  const queryClient = useQueryClient();
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    name,
    description,
    task,
    userAnswer,
    completed,
    completedAt,
    category
  } = spot || {};

  const { mutate: completeSpotMutation, isLoading } = useMutation(
    completeSpot,
    {
      onSuccess: ({ data: updatedSpot }, variables) => {
        const levelUp = updatedSpot.userLeveledUp;

        queryClient.setQueryData(
          [
            "spot",
            { adventureId: variables.adventureId, spotId: variables.spotId }
          ],
          updatedSpot
        );
        queryClient.invalidateQueries("adventure", {
          adventureId: variables.adventureId
        });
        if (editing) {
          setEditing(false);
        }
        if (!editing) {
          navigate("reward", {
            state: { levelUp: levelUp }
          });
        }
      }
    }
  );

  const defaultValues = {
    answer: userAnswer
  };

  const { handleSubmit, control, reset } = useForm({
    defaultValues
  });

  const resetForm = () => {
    reset(defaultValues);
  };

  const handleSpotCompletion = ({ answer }) => {
    const payload = {
      adventureId: adventureId,
      spotId: spot?.id,
      taskType: typeof task !== "undefined" ? task.taskType : "",
      answer: answer
    };
    completeSpotMutation(payload);
  };

  const spotCompletionDate = completedAt
    ? format(parseISO(spot.completedAt), "d.M.yyyy")
    : "";

  return (
    <form onSubmit={handleSubmit(handleSpotCompletion)} className={styles.form}>
      <main className={styles.main}>
        <div className={styles.top}>
          <H1 size="xl" className={styles.title}>
            {name}
          </H1>
          <Text muted>{category?.title}</Text>
        </div>
        <div className={styles.content}>
          <RichText content={description} />
        </div>
        <SpotTask
          resetForm={resetForm}
          control={control}
          task={task}
          completed={completed}
          editing={editing}
          setEditing={setEditing}
        />
      </main>
      {completed && (
        <div className={styles.completionNotice}>
          <H3>
            <Trans
              i18nKey="adventure.spot.completed_notice"
              values={{ date: spotCompletionDate }}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <Text element="span" weight="semibold" />
            </Trans>
          </H3>
          <Button to="reward" round white>
            {t("adventure.spot.show_reward")}
          </Button>
        </div>
      )}
      {!completed && (
        <PageFooter>
          <Button
            type="submit"
            fill
            large
            round
            loading={isLoading}
            disabled={!spot || isLoading}
          >
            {t("adventure.spot.complete_spot_button")}
          </Button>
        </PageFooter>
      )}
    </form>
  );
}

function SpotTask({
  task,
  control,
  completed,
  editing,
  setEditing,
  resetForm
}) {
  const { t } = useTranslation();
  if (!task) {
    return null;
  }

  const toggleEditing = (event) => {
    event.preventDefault();
    setEditing(!editing);
    resetForm();
  };

  const { question, taskType } = task;

  const taskComponents = {
    checkbox: CheckboxTask,
    multiple_choice: RadioTask,
    text: TextTask,
    open_question: OpenTask
  };

  const TaskComponent = taskComponents[taskType.toString()] || "div";

  const disabled = completed && !editing;

  const allowEditing =
    completed && ["checkbox", "open_question"].includes(taskType);

  return (
    <div className={styles.task}>
      <H2 size="xl" className="margin-16 margin-bottom">
        {question}
      </H2>
      <TaskComponent
        label={question}
        task={task}
        control={control}
        disabled={disabled}
      />
      {allowEditing && !editing && (
        <div className={styles.editToolbar}>
          <Button onClick={toggleEditing} transparent>
            {t("adventure.spot.edit_answer")}
          </Button>
        </div>
      )}
      {editing && (
        <div className={styles.editToolbar}>
          <Button alt onClick={toggleEditing}>
            {t("adventure.spot.cancel")}
          </Button>
          <Button type="submit">{t("adventure.spot.save")}</Button>
        </div>
      )}
    </div>
  );
}
