import { useLocation } from "react-router-dom";

// Localization
import { useTranslation } from "react-i18next";

// UI
import {
  Page,
  Button,
  RichText,
  H1,
  Text,
  TopBar,
  ErrorBoundary
} from "@components/common";
import { X } from "react-feather";

// Assets
import RewardBadge from "@/assets/icons/winner-reward.svg";

// Styles
import styles from "./Reward.module.css";

export default function NewLevelReward({ loading }) {
  const { t } = useTranslation();
  const { state: locationState } = useLocation();
  const { backUrl, levelId } = locationState || {};

  return (
    <Page
      color="green"
      loading={loading}
      span
      top={
        <div className={styles.top}>
          <RewardBadge className={styles.icon} />
          <H1>{t("adventure.spot.level_reward")}</H1>
          <div>
            <Text weight="semibold" className={styles.level}>
              {t("adventure.spot.level_reward_message", {
                level: levelId.levelNumber
              })}
            </Text>
          </div>
        </div>
      }
      bottom={
        <Button
          to={backUrl ? backUrl : "../../.."}
          large
          fill
          round
          className={styles.bottomFooter}
        >
          {backUrl
            ? t("adventure.spot.close")
            : t("adventure.spot.back_to_map")}
        </Button>
      }
    >
      <TopBar
        transparent
        absolute
        right={
          <Button to={backUrl ? backUrl : "../../.."} icon={X} white round />
        }
      />
      <ErrorBoundary>
        <div className={styles.content}>
          <RichText content={levelId.reward} />
        </div>
      </ErrorBoundary>
    </Page>
  );
}
