import React from "react";
import cx from "classnames";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { useRadioGroup, useRadio } from "@react-aria/radio";
import { useRadioGroupState } from "@react-stately/radio";

// Styles
import styles from "./Radio.module.css";

// Context
const RadioContext = React.createContext(null);

export function RadioGroup(props) {
  const { label, children } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state);
  return (
    <div {...radioGroupProps} className={styles.group}>
      <VisuallyHidden>
        <span {...labelProps}>{label}</span>
      </VisuallyHidden>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </div>
  );
}

export function Radio(props) {
  const { children, feedback, correct } = props;
  const state = React.useContext(RadioContext);
  const ref = React.useRef(null);
  const { inputProps } = useRadio(props, state, ref);

  const isChecked = state.selectedValue === props.value;
  const isDisabled = state.isDisabled || props.isDisabled;

  return (
    <label
      className={cx(styles.labelContainer, {
        [styles.disabled]: isDisabled,
        [styles.checked]: isChecked,
        [styles.correct]: correct
      })}
    >
      <div
        className={cx(styles.inputContainer, {
          [styles.checked]: isChecked,
          [styles.correct]: correct
        })}
      >
        <VisuallyHidden>
          <input {...inputProps} ref={ref} />
        </VisuallyHidden>
        <span
          aria-hidden
          className={cx(styles.inputIndicator, {
            [styles.checked]: isChecked,
            [styles.correct]: correct
          })}
        />
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.labelText}>{children}</div>
        {isChecked && (
          <div className={cx(styles.feedback, { [styles.correct]: correct })}>
            {feedback}
          </div>
        )}
      </div>
    </label>
  );
}
