// Localization
import { useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// UI
import { Button, H1, Text, IconBadge } from "@components/common";

// Assets
import IconCheck from "@/assets/icons/icon-check.svg";

// Styles
import styles from "./RegisterWelcome.module.css";

export default function RegisterWelcome() {
  const { state: locationState } = useLocation();
  const { email } = locationState || {};
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <IconBadge success icon={IconCheck} className={styles.icon} />

          <H1 className={styles.title}>{t("register_welcome.title")}</H1>
          <Text size="lg">
            <Trans
              i18nKey="register_welcome.sign_up_message"
              values={{ email: email }}
            >
              <strong></strong>
            </Trans>
          </Text>
          <div className={styles.buttonContainer}>
            <Button to="/adventures" large fill>
              {t("register_welcome.start_button")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
