import { NavLink } from "react-router-dom";
import cx from "classnames";

// Localization
import { useTranslation } from "react-i18next";

// UI
import { Map, MapPin, Info, Bell } from "react-feather";

// Styles
import styles from "./BottomNavigation.module.css";

export default function BottomNavigation() {
  const { t } = useTranslation();

  return (
    <nav className={styles.outer}>
      <div className={styles.inner}>
        <BottomNavigationLink icon={Map} to={``}>
          {t("adventure.bottom_navigation.map")}
        </BottomNavigationLink>
        <BottomNavigationLink icon={MapPin} to={`spots`}>
          {t("adventure.bottom_navigation.spots")}
        </BottomNavigationLink>
        <BottomNavigationLink icon={Info} to={`info`}>
          {t("adventure.bottom_navigation.info")}
        </BottomNavigationLink>
        <BottomNavigationLink icon={Bell} to={`notifications`}>
          {t("adventure.bottom_navigation.notifications")}
        </BottomNavigationLink>
      </div>
    </nav>
  );
}

function BottomNavigationLink({ children, to, icon }) {
  const IconComponent = icon;

  const handleClassName = ({ isActive }) => {
    return cx(styles.navLink, { [styles.active]: isActive });
  };

  return (
    <NavLink className={handleClassName} to={to} end>
      <IconComponent className={styles.icon} />
      {children}
    </NavLink>
  );
}
