import { useCallback } from "react";
import { useAuth } from "@/auth.js";
import { useQuery } from "react-query";
import { deserialize } from "deserialize-json-api";
import { useNavigate } from "react-router-dom";

export function useAuthQuery(queryKey, queryFn, options = {}) {
  const originalOnError = options.onError;
  const { setIsLoggedIn } = useAuth();

  const navigate = useNavigate();

  const deserializedQueryFn = useCallback(
    (...args) => {
      return queryFn(...args).then((response) => {
        const deserialized = deserialize(response);
        const data = deserialized.data;
        data.meta = deserialized.meta;
        data.errors = deserialized.errors;
        return data;
      });
    },
    [queryFn]
  );

  return useQuery(queryKey, deserializedQueryFn, {
    ...options,
    onError: (error) => {
      if (error?.response?.status === 401) {
        setIsLoggedIn(false);
      } else if (error?.response?.status === 404) {
        navigate("/adventures");
      } else {
        originalOnError && originalOnError(error);
      }
    }
  });
}
