import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/auth.js";

export default function Unauthenticated({ children }) {
  const { isLoggingIn, isLoggedIn, userRole } = useAuth();
  const navigate = useNavigate();
  const defaultRedirectPath = "/adventures";

  useEffect(() => {
    if (!isLoggingIn && isLoggedIn) {
      let lastPath = defaultRedirectPath;
      if (userRole === "visitor") {
        lastPath = localStorage.getItem("redirectPath");
      }
      navigate(lastPath);
    }
  }, [isLoggingIn, isLoggedIn, navigate, userRole]);

  return children;
}
