// Styles
import styles from "./RichText.module.css";

export function RichText({ content }) {
  const parsedContent = processContent(content);
  return (
    <div
      className={styles.container}
      dangerouslySetInnerHTML={{ __html: parsedContent }}
    />
  );
}

function processContent(content) {
  // Make links open in a new tab
  let result = content?.replace(
    "<a ",
    "<a target='_blank' rel='noopener noreferrer' "
  );

  return result;
}
