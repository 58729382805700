import { useMutation as useReactQueryMutation } from "react-query";
import { deserialize } from "deserialize-json-api";

export function useMutation(mutationFn, options = {}) {
  const originalOnSuccess = options.onSuccess;

  return useReactQueryMutation(mutationFn, {
    ...options,
    onSuccess: (data, variables, context) => {
      const deserializedData = deserialize(data);
      originalOnSuccess(deserializedData, variables, context);
    }
  });
}
