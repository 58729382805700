// UI
import { IconBadge, Text, H2 } from "@components/common";

// Styles
import styles from "./CenteredContent.module.css";

export function CenteredContent({
  icon,
  title,
  text,
  children,
  success,
  gray,
  danger
}) {
  return (
    <div className={styles.outer}>
      <div className={styles.inner}>
        {icon && (
          <IconBadge
            icon={icon}
            className={styles.icon}
            success={success}
            gray={gray}
            danger={danger}
          />
        )}
        {(title || text) && (
          <div>
            {title && <H2 className={styles.title}>{title}</H2>}
            {text && <Text element="div">{text}</Text>}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
