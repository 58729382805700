// Localization
import { useTranslation, Trans } from "react-i18next";

// UI
import { H1, H2, Page, Text, TextLink } from "@components/common";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel
} from "@reach/accordion";
import "@reach/accordion/styles.css";

// Assets
import pinLockedFavourite from "@/assets/pins/pin-locked-favourite.png";
import pinVisitedFavourite from "@/assets/pins/pin-visited-favourite.png";
import pinCompletedFavourite from "@/assets/pins/pin-completed-favourite.png";
import spotRadius from "@/assets/images/spot-radius.png";
import spotRadiusSv from "@/assets/images/spot-radius-sv.png";

import { ChevronDown } from "react-feather";

// Styles
import styles from "./AdventureInfo.module.css";

export default function AdventureInfo({ spots }) {
  const { t, i18n } = useTranslation();

  const categories = spots.reduce((accumulator, currentSpot) => {
    const currentCategory = currentSpot.category;
    if (
      currentCategory?.title &&
      !accumulator.some((category) => category.title === currentCategory.title)
    ) {
      accumulator.push(currentCategory);
    }
    return accumulator;
  }, []);

  const selectImageForLocale = (locale) => {
    const imageMap = {
      fi: spotRadius,
      sv: spotRadiusSv
    };
    // Default to 'fi' if no matching locale is found
    return imageMap[locale] || imageMap.fi;
  };

  return (
    <Page
      top={
        <>
          {" "}
          <H1 className="margin-8 margin-bottom">
            {t("adventure.info.intro_title")}
          </H1>
          <Text>{t("adventure.info.intro_paragraph")}</Text>
          <br />
          <Text>
            <Trans i18nKey="adventure.info.location_help">
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <TextLink
                href={t("common.location_url ")}
                className={styles.link}
                target="_blank"
              />
            </Trans>
          </Text>
          <br />
          <Text>
            <Trans i18nKey="adventure.info.contact_info">
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <TextLink
                href={t("common.contact_url ")}
                className={styles.link}
                target="_blank"
              />
            </Trans>
          </Text>
        </>
      }
    >
      <Accordion collapsible>
        <ContentBlock title={t("adventure.info.spots_title")}>
          <Text>{t("adventure.info.spots_paragraph")}</Text>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <img src={pinLockedFavourite} alt="" className={styles.listImg} />
              {t("adventure.info.spots_beige")}
            </li>
            <li className={styles.listItem}>
              <img
                src={pinVisitedFavourite}
                alt=""
                className={styles.listImg}
              />
              {t("adventure.info.spots_orange")}
            </li>
            <li className={styles.listItem}>
              <img
                src={pinCompletedFavourite}
                alt=""
                className={styles.listImg}
              />
              {t("adventure.info.spots_green")}
            </li>
          </ul>
          <Text>{t("adventure.info.spots_paragraph2")}</Text>
          <img
            src={selectImageForLocale(i18n.language)}
            alt=""
            className={styles.image}
          />
        </ContentBlock>
        <ContentBlock title={t("adventure.info.levels_title")}>
          <Text>{t("adventure.info.levels_paragraph")}</Text>
        </ContentBlock>
        <ContentBlock title={t("adventure.info.category_title")}>
          <Text>{t("adventure.info.category_paragraph")}</Text>
          <ul className={styles.list}>
            {categories.map((category, index) => (
              <li key={index} className={styles.bulletListItem}>
                <Text weight="semibold">{category.title}</Text>
                <Text size="sm">{category.description}</Text>
              </li>
            ))}
          </ul>
        </ContentBlock>
      </Accordion>
    </Page>
  );
}

function ContentBlock({ title, paragraph, children }) {
  return (
    <AccordionItem className={styles.accordionItem}>
      <H2 size="lg">
        <AccordionButton className={styles.accordionButton}>
          <div>{title}</div>
          <ChevronDown className={styles.accordionChevron} />
        </AccordionButton>
      </H2>
      <AccordionPanel>
        <div className={styles.accordionContent}>{children}</div>
      </AccordionPanel>
    </AccordionItem>
  );
}
