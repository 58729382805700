import cx from "classnames";

// UI
import { Button, H1, Text } from "@components/common";
import { ArrowLeft } from "react-feather";

// Styles
import styles from "./TopBar.module.css";

export function TopBar({
  title,
  subtitle,
  right,
  left,
  transparent,
  absolute,
  backTo
}) {
  return (
    <div
      className={cx(styles.outer, {
        [styles.transparent]: transparent,
        [styles.absolute]: absolute
      })}
    >
      <div className={styles.inner}>
        <div className={cx(styles.section, styles.left)}>
          {backTo && (
            <Button
              to={backTo}
              icon={ArrowLeft}
              white
              // If the topBar is transparent we don't want the button to be transparent
              transparent={!transparent}
              round
            />
          )}
          {left}
        </div>
        <div className={cx(styles.section, styles.middle)}>
          <H1 size="lg" className={styles.title}>
            {title}
          </H1>
          {subtitle && (
            <Text size="sm" element="span" className={styles.subtitle}>
              {subtitle}
            </Text>
          )}
        </div>
        <div className={cx(styles.section, styles.right)}>{right}</div>
      </div>
    </div>
  );
}
