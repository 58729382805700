import { Honeybadger } from "@honeybadger-io/react";

let honeybadger;

// Confiq
const config = {
  apiKey: "hbp_LhOFvCNr8QonxmCMNcOmOby7C0u2qS0ScChE",
  environment: process.env.ENVIRONMENT || process.env.NODE_ENV,
  revision: process.env.GIT_COMMIT || "master",
  debug: process.env.NODE_ENV !== "production",
  disabled: process.env.NODE_ENV !== "production"
};

honeybadger = Honeybadger.configure(config);

honeybadger.setContext({
  user_email: window.localStorage.getItem(`${process.env.SITE}-user`)
});

export default honeybadger;
