// With import webpack processes the image file and replaces logoSrc with the final path to the processed image file
import cityspottingLogoWhite from "@/assets/images/logo-white.png";
import photo from "@/assets/photos/login-photo.jpg";
import cityspottingLogoSrc from "@/assets/images/logo.png";

import mapiosaLogoWhite from "@/assets/images/logo-white-mapiosa.png";
import mapiosaPhoto from "@/assets/photos/mapiosa-login-photo.jpg";
import mapiosaLogoSrc from "@/assets/images/logo-mapiosa.png";

const siteConfig = {
  cityspotting: {
    siteTitle: "Cityspotting",
    siteTitleTM: "Cityspotting™",
    logoSrc: cityspottingLogoSrc,
    logoWhiteSrc: cityspottingLogoWhite,
    logoAlt: "Cityspotting logo",
    bgPhoto: photo
  },
  mapiosa: {
    siteTitle: "Mapiosa",
    siteTitleTM: "Mapiosa™",
    logoSrc: mapiosaLogoSrc,
    logoWhiteSrc: mapiosaLogoWhite,
    logoAlt: "Mapiosa logo",
    bgPhoto: mapiosaPhoto
  }
};

const defaultSite = "cityspotting"; // replace with your actual default site

const siteName = process.env.SITE || defaultSite;
const currentSiteConfig = siteConfig[siteName];

export const {
  siteTitle,
  siteTitleTM,
  logoSrc,
  logoWhiteSrc,
  logoAlt,
  bgPhoto
} = currentSiteConfig;

export default currentSiteConfig;
