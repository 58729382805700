import { Link } from "react-router-dom";
import cx from "classnames";

// Components
import { Loader } from "@components/common";

// Styles
import styles from "./Button.module.css";

export function Button({
  children,
  fill,
  transparent,
  white,
  round,
  icon,
  loading,
  loadingText,
  large,
  small,
  to,
  className,
  disabled,
  alt,
  ...props
}) {
  const Wrapper = to ? Link : "button";
  const IconComponent = icon;
  return (
    <Wrapper
      className={cx(
        styles.reset,
        styles.base,
        styles.default,
        {
          [styles.fill]: fill,
          [styles.transparent]: transparent,
          [styles.white]: white,
          [styles.alt]: alt,
          [styles.round]: round,
          [styles.large]: large,
          [styles.small]: small,
          [styles.hasIcon]: icon && children,
          [styles.onlyIcon]: icon && !children
        },
        className
      )}
      to={to}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <>
          <Loader size="sm" color="gray" />
          <div className={styles.loadingText}>{loadingText}</div>
        </>
      ) : (
        <>
          {icon && <IconComponent className={styles.icon} />}
          {children}
        </>
      )}
    </Wrapper>
  );
}
