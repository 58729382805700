import ky from "ky";

import {
  getSessionStorage,
  setSessionStorage,
  removeSessionStorage
} from "../auth";

const API_URL = "/api/";

export const publicClient = ky.extend({
  prefixUrl: API_URL,
  retry: {
    limit: 0
  },
  hooks: {
    afterResponse: [
      async (_request, _options, response) => {
        if (response.headers.get("access-token")) {
          setSessionStorage({
            accessToken: response.headers.get("access-token"),
            uid: response.headers.get("uid"),
            client: response.headers.get("client")
          });
        }

        return response;
      }
    ]
  }
});

export const privateClient = publicClient.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        const session = getSessionStorage();

        if (session) {
          request.headers.set("access-token", session.accessToken);
          request.headers.set("uid", session.uid);
          request.headers.set("client", session.client);
        }
      }
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (response.status === 401) {
          removeSessionStorage();
        }

        return response;
      }
    ]
  }
});
