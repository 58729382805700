import { useLocation, useParams, useNavigate } from "react-router-dom";

// Localization
import { useTranslation } from "react-i18next";

// UI
import {
  Page,
  Button,
  RichText,
  H1,
  TopBar,
  ErrorBoundary
} from "@components/common";
import { X } from "react-feather";

// Assets
import IconCheck from "@/assets/icons/icon-check-bold.svg";

// Styles
import styles from "./Reward.module.css";
import { Fragment } from "react";

export default function Reward({ loading, spot }) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const { state: locationState } = useLocation();

  const { levelUp, backUrl } = locationState || {};

  const { reward } = spot || {};

  const handleNextLevel = () => {
    navigate(
      `/adventure/${params.adventureId}/spot/${params.spotId}/reward/reward-level`,
      {
        state: { levelUp: levelUp }
      }
    );
  };

  return (
    <Page
      color="green"
      loading={loading}
      span
      top={
        <div className={styles.top}>
          <IconCheck className={styles.icon} />
          <H1>{t("adventure.spot.reward_title")}</H1>
        </div>
      }
      bottom={
        <Fragment>
          {levelUp ? (
            <Button onClick={handleNextLevel} large fill round>
              {t("adventure.spot.next_level")}
            </Button>
          ) : (
            <Button to={backUrl ? backUrl : "../../.."} large fill round>
              {backUrl
                ? t("adventure.spot.close")
                : t("adventure.spot.back_to_map")}
            </Button>
          )}
        </Fragment>
      }
    >
      <TopBar
        transparent
        absolute
        right={
          <Button to={backUrl ? backUrl : "../../.."} icon={X} white round />
        }
      />
      <ErrorBoundary>
        <div className={styles.content}>
          <RichText content={reward} />
        </div>
      </ErrorBoundary>
    </Page>
  );
}
