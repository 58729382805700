import { Controller } from "react-hook-form";

// Styles
import styles from "./CheckboxField.module.css";

export function CheckboxField({
  label,
  name,
  control,
  ...otherProps
}) {
  return (
    <Controller
      control={control}
      name={name}
      {...otherProps}
      render={({ field, fieldState }) => {
        return (
          <div className={styles.container}>
            <label 
              htmlFor={name}
              className={styles.labelContainer}
            >
              <input
                type="checkbox"
                id={name}
                {...field}
              />
              {label}
            </label>
            {fieldState.error && (
              <div className={styles.error}>{fieldState.error.message}</div>
            )}
          </div>
        );
      }}
    />
  );
}
