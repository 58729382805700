// Localization
import { useTranslation } from "react-i18next";
import { siteTitle } from "@/siteConfig";

// UI
import {
  NotificationInline,
  Button,
  H1,
  Text,
  IconBadge
} from "@components/common";

// Assets
import IconCheck from "@/assets/icons/icon-check.svg";

// Styles
import styles from "./AuthenticationForm.module.css";

export function AuthenticationForm({
  onSubmit,
  title,
  children,
  errorMessage,
  submitText,
  footer,
  loading,
  loadingText,
  successMessage,
  successButton,
  successTo,
  successIcon
}) {
  const { t } = useTranslation();

  return (
    <>
      {successMessage ? (
        <SuccessContainer
          successMessage={successMessage}
          successButton={successButton}
          successTo={successTo}
          successIcon={successIcon}
        />
      ) : (
        <>
          <Text weight="semibold" className={styles.subtitle}>
            {siteTitle}
          </Text>
          <H1 size={"xxl"} className={styles.title}>
            {title}
          </H1>
          <form onSubmit={onSubmit} className={styles.form}>
            <div className={styles.inputs}>{children}</div>
            {errorMessage && (
              <NotificationInline
                message={errorMessage}
                className={styles.error}
              />
            )}
            <div className={styles.submit}>
              <Button
                large
                fill
                type="submit"
                loading={loading}
                loadingText={loadingText || t("common.loading")}
              >
                {submitText}
              </Button>
            </div>
            <div className={styles.footer}>{footer}</div>
          </form>
        </>
      )}
    </>
  );
}

function SuccessContainer({
  successMessage,
  successButton,
  successTo,
  successIcon
}) {
  const IconComponent = successIcon ? successIcon : IconCheck;
  return (
    <div className={styles.success}>
      <IconBadge success icon={IconComponent} className={styles.successIcon} />
      <Text size="lg" className={styles.successMessage}>
        {successMessage}
      </Text>
      <Button large fill to={successTo}>
        {successButton}
      </Button>
    </div>
  );
}
