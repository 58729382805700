import { HoneybadgerErrorBoundary } from "@honeybadger-io/react";
import honeybadger from "./honeybadgerConfiguration";

// i18n
import { useTranslation } from "react-i18next";

// UI
import { CenteredContent } from "../index";

// Assets
import IconAlert from "@/assets/icons/icon-alert.svg";

// Styles
import styles from "./ErrorBoundary.module.css";

export function ErrorBoundary({ children, label }) {
  let Component = DefaultErrorComponent;

  return (
    <HoneybadgerErrorBoundary
      honeybadger={honeybadger}
      ErrorComponent={(errorProps) => (
        <Component {...errorProps} {...(label && { label: label })} />
      )}
    >
      <>{children}</>
    </HoneybadgerErrorBoundary>
  );
}

function DefaultErrorComponent({ error }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <CenteredContent
        icon={IconAlert}
        danger
        title={t("common.error")}
        text={`${t("common.error_message")}: ${error.toString()}`}
      />
    </div>
  );
}
