import cx from "classnames";

// UI
import { Text } from "../Text/Text";

// Styles
import styles from "./Heading.module.css";

export function H1({ size, ...props }) {
  return <Heading element={"h1"} size={size || "xxl"} {...props} />;
}
export function H2({ size, ...props }) {
  return <Heading element={"h2"} size={size || "xl"} {...props} />;
}
export function H3({ size, ...props }) {
  return <Heading element={"h3"} size={size || "lg"} {...props} />;
}
export function H4({ size, ...props }) {
  return <Heading element={"h4"} size={size || "base"} {...props} />;
}
export function H5({ size, ...props }) {
  return <Heading element={"h5"} size={size || "base"} {...props} />;
}
export function H6({ size, ...props }) {
  return <Heading element={"h6"} size={size || "base"} {...props} />;
}

function Heading({ element, size, className, children, ...otherProps }) {
  const HX = element;
  return (
    <Text
      element={HX}
      className={cx(styles.heading, styles[size.toString()], className)}
      weight={"bold"}
      {...otherProps}
    >
      {children}
    </Text>
  );
}
