// Localization
import { useTranslation } from "react-i18next";

// UI
import { TextField } from "@components/common";

// Styles
import styles from "./TextTask.module.css";

export default function TextTask({ task, control, disabled }) {
  const { t } = useTranslation();

  const { textAnswer } = task;
  const correctAnswers = textAnswer
    .split(",")
    .map((answer) => [answer.trim(), answer.trim().toLowerCase()]).flat();

  const validateAnswer = (value) => {
    return correctAnswers.includes(value) || t("adventure.spot.wrong_answer");
  };

  return (
    <div className={styles.container}>
      <TextField
        label={t("adventure.spot.text_task_label")}
        type="text"
        name="answer"
        control={control}
        disabled={disabled}
        rules={{
          validate: validateAnswer,
          required: {
            value: true,
            message: t("adventure.spot.text_task_required")
          }
        }}
      />
    </div>
  );
}
