// Localization
import { useTranslation, Trans } from "react-i18next";

import { logoWhiteSrc, logoAlt } from "@/siteConfig";

// UI
import { Button, Text, TextLink } from "@components/common";

// Styles
import styles from "./Welcome.module.css";
// Align mapiosa logo
const logoClass =
  process.env.SITE === "mapiosa"
    ? `${styles.logo} ${styles.logoMapiosa}`
    : styles.logo;

export default function Welcome() {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.content}>
        <img src={logoWhiteSrc} alt={logoAlt} className={logoClass} />
        <div className={styles.textContent}>
          <Text size="lg">{t("login.intro")}</Text>
          <Text size="lg">
            <Trans i18nKey="login.intro_part_2">
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <TextLink href={t("common.site_url ")} className={styles.link} />
            </Trans>
          </Text>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button to="/register" fill large round>
          {t("login.sign_up")}
        </Button>
        <Button to="/login" fill large round white>
          {t("login.login")}
        </Button>
      </div>
    </>
  );
}
