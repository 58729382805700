import { publicClient, privateClient } from "./client";
import i18n from "i18next";

export async function signIn({ email, password }) {
  return await publicClient
    .post("auth/sign_in", { json: { email, password, locale: i18n.language } })
    .json();
}

export async function signUp({ email, password, passwordConfirmation, acceptedTermsOfUse, acceptedMarketingConsent }) {
  return await publicClient
    .post("auth", {
      json: {
        email,
        password,
        password_confirmation: passwordConfirmation,
        locale: i18n.language,
        accepted_terms_of_use: acceptedTermsOfUse,
        accepted_marketing_consent: acceptedMarketingConsent
      }
    })
    .json();
}

export async function forgotPassword({ email }) {
  return await publicClient
    .post("auth/password", {
      json: {
        email,
        redirect_url: `${window.location.origin}/password`,
        locale: i18n.language
      }
    })
    .json();
}

export async function signOut() {
  return await privateClient.delete("auth/sign_out").json();
}

export async function validateToken({ accessToken, uid, client }) {
  return await publicClient
    .get("auth/validate_token", {
      searchParams: {
        "access-token": accessToken,
        uid,
        client
      }
    })
    .json();
}
