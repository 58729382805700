import { Controller } from "react-hook-form";

// UI
import { RadioGroup, Radio } from "@components/common";

// Styles
import styles from "./RadioTask.module.css";

export default function RadioTask({ label, task, control, disabled }) {
  const { options } = task;

  return (
    <div className={styles.container}>
      <Controller
        name={"answer"}
        control={control}
        render={({ field: { onChange, value } }) => {
          // CheckboxGroup requires the values as array of strings so we have to convert them
          const valueAsString = value?.toString();
          return (
            <RadioGroup
              defaultValue={valueAsString}
              onChange={onChange}
              label={label}
            >
              {options.map((option) => (
                <Radio
                  key={option.id}
                  value={option.id}
                  correct={option.correctAnswer}
                  feedback={option.answerFeedback}
                  isDisabled={disabled}
                >
                  {option.answer}
                </Radio>
              ))}
            </RadioGroup>
          );
        }}
      />
    </div>
  );
}
